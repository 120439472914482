import { App } from "@capacitor/app";

async function getInfo(): Promise<BuildInfo> {
  return await App.getInfo();
}

async function resumeListener(
  resumeHandler: AppDetails.ResumeListener
): Promise<PluginListenerHandle> {
  return await App.addListener("resume", resumeHandler);
}

function close() {
  void App.exitApp();
}

async function restart() {
  await App.removeAllListeners();
  document.location.href = "index.html";
}

export const app: AppDetails.Implementation = {
  close,
  getInfo,
  restart,
  resumeListener
};
